<template>
  <div>
    <v-card>
      <v-card-title>Administrar falácias</v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="fallacies"
          disable-pagination
          hide-default-footer
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                small
                color="success"
                @click="$router.push('/admin/falacias/cadastrar')"
                >Cadastrar +</v-btn
              >
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="mr-2"
              title="Editar dados"
              @click="editFallacy(item.id)"
              icon
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="mr-2"
              title="Excluir"
              @click="deleteFallacy(item.id)"
              icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  computed: {},
  data: () => ({
    loading: false,
    headers: [
      { text: "Descrição", value: "description" },
      { text: "Grupo", value: "group" },
      { text: "Ações", value: "actions" },
    ],
    fallacies: [],
  }),
  methods: {
    async loadFallacies() {
      try {
        this.loading = true;

        const response = await this.$axios.get(`/falacias`);

        this.setFallacies(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setFallacies(data) {
      const cpData = [...data];

      this.fallacies = cpData.map((f) => ({
        ...f,
      }));
    },
    async deleteFallacy(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Alerta de AÇÃO IRREVERSÍVEL",
          `Deseja realmente deletar esta falácia do sistema?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/falacias/${id}`;

        await this.$axios.delete(url);

        this.deleteFallacyFromTable(id);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    deleteFallacyFromTable(id) {
      const index = this.fallacies.findIndex((f) => f.id === id);

      this.fallacies.splice(index, 1);
    },
    editFallacy(id) {
      this.$router.push(`/admin/falacias/${id}/editar`);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  created() {
    this.loadFallacies();
  },
};
</script>

<style></style>
